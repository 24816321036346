import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import { CustomerDto } from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class CustomerApiClient extends BaseCRUDApiClient<CustomerDto> {
  baseUrl = this.config.apiEndpoint + '/customer';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }
}
